<template>
  <td
    v-if="isLoading"
    :colspan="colspanLength"
  >
    <v-row justify="center">
      <v-col cols="auto">
        <v-progress-circular
          class="mx-auto"
          color="primary"
          size="30"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </td>
  <td
    v-else
    class="white"
    :colspan="colspanLength"
  >
    <v-container fluid>
      <v-sheet outlined class="pa-2 rounded">
        <v-data-table
          :items-per-page="100"
          :headers="displayedHeader"
          :items="items"
          item-key="id"
          hide-default-footer
          :server-items-length="1000"
        >
          <template v-slot:[`item.no`]="{item,index}">
            <span :class="selectedRows.includes(item.id) ? 'bg-red' : ''">{{ index + 1 }}</span>
            <v-btn
              x-small
              icon
              class="ml-2"
              color="primary"
              @click="() => selectedRow(item)"
            >
              <v-icon>mdi-format-line-spacing</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.isActive`]="{item}">
            <v-switch
              :loading="item.updatingStatus"
              v-model="item.isActive"
              @change="handleUpdateStatus(item, $event)"
            ></v-switch>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <v-tooltip top v-if="userAccess.canUpdate">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  icon
                  class="mr-2"
                  color="primary"
                  @click="() => showDialogUpdateMenu(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.EDIT}}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-sheet>
      <DialogUpdateMenu
        ref="dialogUpdateMenu"
        typeUpdate="child"
        @fetchData="$emit('fetchData')"
        @changeItemOnItems="changeItemOnItems"
      />
    </v-container>
  </td>
</template>

<script>
import DialogUpdateMenu from './Dialog/UpdateMenu.vue';

export default {
  components: {
    DialogUpdateMenu,
  },
  props: {
    parentMenuId: {
      type: Number,
      default: 0,
    },
    childItems: {
      type: Array,
      default: () => [],
    },
    colspanLength: {
      type: Number,
      default: () => 6,
    },
  },
  data() {
    return {
      items: [...this.childItems],
      isLoading: false,
      selectedRows: [],
      headers: [
        {
          text: 'No',
          value: 'no',
          width: '10%',
          sortable: false,
        },
        {
          text: 'Sub Menu',
          value: 'menuName',
          width: '20%',
          sortable: false,
        },
        {
          text: 'Path',
          value: 'url',
          width: '20%',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'isActive',
          width: '20%',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'actions',
          width: '20%',
          sortable: false,
        },
      ],
    };
  },
  watch: {
    childItems(newVal) {
      this.items = newVal;
    },
  },
  computed: {
    displayedHeader() {
      const { canUpdate } = this.userAccess;
      return this.headers.map((i) => ({
        ...i,
        class: 'black--text amber accent-3',
      })).filter((header) => {
        if (!canUpdate) {
          return header.value !== 'actions';
        }
        return header;
      });
    },
  },
  methods: {
    async selectedRow(item) {
      if (this.selectedRows.includes(item.id)) {
        this.selectedRows = this.selectedRows.filter((s) => s !== item.id);
        return;
      }
      if (this.selectedRows.length === 2) return;
      this.selectedRows = [...this.selectedRows, item.id];
      if (this.selectedRows.length === 2) {
        const newItems = [...this.items];
        const findIdx1 = this.items.findIndex((i) => i.id === this.selectedRows[0]);
        const findIdx2 = this.items.findIndex((i) => i.id === this.selectedRows[1]);
        const findItemIdx1 = { ...this.items.find((i) => i.id === this.selectedRows[0]) };
        const findItemIdx2 = { ...this.items.find((i) => i.id === this.selectedRows[1]) };
        const form1 = {
          ...findItemIdx1,
          sort: findIdx2,
        };
        const form2 = {
          ...findItemIdx2,
          sort: findIdx1,
        };
        await this.$_services.menu.updateMenu({ form: form1, menuId: form1.id });
        await this.$_services.menu.updateMenu({ form: form2, menuId: form2.id });
        newItems.splice(findIdx1, 1, findItemIdx2);
        newItems.splice(findIdx2, 1, findItemIdx1);
        this.items = newItems;
        this.selectedRows = [];
      }
    },
    changeItemOnItems({ id, menuName }) {
      this.$emit('changeChildItemOnItems', { parentMenuId: this.parentMenuId, childMenuId: id, menuName });
    },
    async handleUpdateStatus(item, event) {
      try {
        this.$set(item, 'updatingStatus', true);
        const form = {
          ...item,
          isActive: event,
        };
        await this.$_services.menu.updateMenu({ menuId: item.id, form });
      } catch {
        this.$nextTick(() => {
          item.isActive = !event;
        });
      } finally {
        this.$delete(item, 'updatingStatus');
      }
    },
    showDialogUpdateMenu(item) {
      this.$refs.dialogUpdateMenu.id = item.id;
      this.$refs.dialogUpdateMenu.form.menuName = item.menuName;
      this.$refs.dialogUpdateMenu.form.url = item.url;
      this.$refs.dialogUpdateMenu.form.icon = item.icon;
      this.$refs.dialogUpdateMenu.form.sort = item.sort;
      this.$refs.dialogUpdateMenu.form.isActive = item.isActive;
      this.$refs.dialogUpdateMenu.dialog = true;
    },
  },
};
</script>
