<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-data-table
          show-expand
          :expanded.sync="expanded"
          :items-per-page="100"
          :loading="isLoading"
          :headers="displayedHeader"
          :items="items"
          item-key="id"
          hide-default-footer
          :server-items-length="1000"
          :options.sync="pagination"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
        >
          <template v-slot:[`item.no`]="{item,index}">
            <span :class="selectedRows.includes(item.id) ? 'bg-red' : ''">
              {{ (pagination.page - 1) * pagination.itemsPerPage + index + 1 }}
            </span>
            <v-btn
              x-small
              icon
              class="ml-2"
              color="primary"
              @click="() => selectedRow(item)"
            >
              <v-icon>mdi-format-line-spacing</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.isActive`]="{item}">
            <v-switch
              :loading="item.updatingStatus"
              v-model="item.isActive"
              @change="handleUpdateStatus(item, $event)"
            ></v-switch>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <v-tooltip top v-if="userAccess.canUpdate">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  icon
                  class="mr-2"
                  color="primary"
                  @click="() => showDialogUpdateMenu(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.EDIT}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
            <v-icon
              v-if="item.childMenu && item.childMenu.length"
              :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
              @click.stop="expand(!isExpanded)"
            >
              $expand
            </v-icon>
          </template>
          <template class="d-none" v-slot:expanded-item="{ headers, item }">
            <ExpandedItemList
              :parentMenuId="item.id"
              :childItems="item.childMenu"
              :colspanLength="displayedHeader.length"
              @fetchData="fetchData"
              @changeChildItemOnItems="changeChildItemOnItems"
            />
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              Detail Role
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogUpdateMenu
      ref="dialogUpdateMenu"
      @fetchData="fetchData"
      @changeItemOnItems="changeItemOnItems"
    />
  </section>
</template>

<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import ExpandedItemList from './ExpandedItemList.vue';
import DialogUpdateMenu from './Dialog/UpdateMenu.vue';

export default {
  name: 'page-master-menu',
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogUpdateMenu,
    ExpandedItemList,
  },
  data() {
    return {
      isLoading: false,
      expanded: [],
      selectedRows: [],
      headers: [
        {
          text: 'No',
          value: 'no',
          width: '10%',
          sortable: false,
        },
        {
          text: 'Menu',
          value: 'menuName',
          width: '20%',
          sortable: false,
        },
        {
          text: 'Path',
          value: 'url',
          width: '20%',
          sortable: false,
        },
        {
          text: 'Icon',
          value: 'icon',
          width: '20%',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'isActive',
          width: '20%',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'actions',
          width: '20%',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
          cellClass: 'clickable',
        },
      ],
      items: [],
      pagination: defaultPagination(),
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchData();
      },
      deep: true,
    },
  },
  computed: {
    displayedHeader() {
      const { canUpdate } = this.userAccess;
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
      })).filter((header) => {
        if (!canUpdate) {
          return header.value !== 'actions';
        }
        return header;
      });
    },
  },
  methods: {
    async handleUpdateStatus(item, event) {
      try {
        this.$set(item, 'updatingStatus', true);
        const form = {
          ...item,
          isActive: event,
        };
        await this.$_services.menu.updateMenu({ menuId: item.id, form });
      } catch {
        this.$nextTick(() => {
          item.isActive = !event;
        });
      } finally {
        this.$delete(item, 'updatingStatus');
      }
    },
    async selectedRow(item) {
      if (this.selectedRows.includes(item.id)) {
        this.selectedRows = this.selectedRows.filter((s) => s !== item.id);
        return;
      }
      if (this.selectedRows.length === 2) return;
      this.selectedRows = [...this.selectedRows, item.id];
      if (this.selectedRows.length === 2) {
        const newItems = [...this.items];
        const findIdx1 = this.items.findIndex((i) => i.id === this.selectedRows[0]);
        const findIdx2 = this.items.findIndex((i) => i.id === this.selectedRows[1]);
        const findItemIdx1 = this.items.find((i) => i.id === this.selectedRows[0]);
        const findItemIdx2 = this.items.find((i) => i.id === this.selectedRows[1]);
        const form1 = {
          ...findItemIdx1,
          sort: findIdx2,
        };
        const form2 = {
          ...findItemIdx2,
          sort: findIdx1,
        };
        await this.$_services.menu.updateMenu({ form: form1, menuId: form1.id });
        await this.$_services.menu.updateMenu({ form: form2, menuId: form2.id });
        newItems.splice(findIdx1, 1, findItemIdx2);
        newItems.splice(findIdx2, 1, findItemIdx1);
        this.items = newItems;
        this.selectedRows = [];
      }
    },
    changeItemOnItems({ id, menuName }) {
      this.items = this.items.map((i) => {
        if (i.id === id) {
          return {
            ...i,
            menuName,
          };
        }
        return i;
      });
    },
    changeChildItemOnItems({ parentMenuId, childMenuId, menuName }) {
      this.items = this.items.map((i) => {
        if (i.id === parentMenuId) {
          return {
            ...i,
            child: i.child.map((c) => {
              if (c.id === childMenuId) {
                return {
                  ...c,
                  menuName,
                };
              }
              return c;
            }),
          };
        }
        return i;
      });
    },
    showDialogUpdateMenu(item) {
      this.$refs.dialogUpdateMenu.id = item.id;
      this.$refs.dialogUpdateMenu.form.menuName = item.menuName;
      this.$refs.dialogUpdateMenu.form.url = item.url;
      this.$refs.dialogUpdateMenu.form.icon = item.icon;
      this.$refs.dialogUpdateMenu.form.sort = item.sort;
      this.$refs.dialogUpdateMenu.form.isActive = item.isActive;
      this.$refs.dialogUpdateMenu.dialog = true;
    },
    async fetchData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filter = skipEmptyStringObject({
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
      });
      const { appType } = this.filter;
      try {
        this.expanded = [];
        this.isLoading = true;
        const result = await this.$_services.menu.fetchMenu({ filter, appType });
        this.items = result.data;
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      try {
        this.submiting = true;
        this.defaultItems = [...this.items];
      } finally {
        this.submiting = false;
      }
    },
  },
};
</script>
