var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('td',{attrs:{"colspan":_vm.colspanLength}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"color":"primary","size":"30","indeterminate":""}})],1)],1)],1):_c('td',{staticClass:"white",attrs:{"colspan":_vm.colspanLength}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"pa-2 rounded",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items-per-page":100,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"id","hide-default-footer":"","server-items-length":1000},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{class:_vm.selectedRows.includes(item.id) ? 'bg-red' : ''},[_vm._v(_vm._s(index + 1))]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function () { return _vm.selectedRow(item); }}},[_c('v-icon',[_vm._v("mdi-format-line-spacing")])],1)]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"loading":item.updatingStatus},on:{"change":function($event){return _vm.handleUpdateStatus(item, $event)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function () { return _vm.showDialogUpdateMenu(item); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e()]}}],null,true)})],1),_c('DialogUpdateMenu',{ref:"dialogUpdateMenu",attrs:{"typeUpdate":"child"},on:{"fetchData":function($event){return _vm.$emit('fetchData')},"changeItemOnItems":_vm.changeItemOnItems}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }