var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"show-expand":"","expanded":_vm.expanded,"items-per-page":100,"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"id","hide-default-footer":"","server-items-length":1000,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{class:_vm.selectedRows.includes(item.id) ? 'bg-red' : ''},[_vm._v(" "+_vm._s((_vm.pagination.page - 1) * _vm.pagination.itemsPerPage + index + 1)+" ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function () { return _vm.selectedRow(item); }}},[_c('v-icon',[_vm._v("mdi-format-line-spacing")])],1)]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"loading":item.updatingStatus},on:{"change":function($event){return _vm.handleUpdateStatus(item, $event)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function () { return _vm.showDialogUpdateMenu(item); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e()]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.childMenu && item.childMenu.length)?_c('v-icon',{class:['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v(" $expand ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('ExpandedItemList',{attrs:{"parentMenuId":item.id,"childItems":item.childMenu,"colspanLength":_vm.displayedHeader.length},on:{"fetchData":_vm.fetchData,"changeChildItemOnItems":_vm.changeChildItemOnItems}})]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" Detail Role "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1),_c('DialogUpdateMenu',{ref:"dialogUpdateMenu",on:{"fetchData":_vm.fetchData,"changeItemOnItems":_vm.changeItemOnItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }