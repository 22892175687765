<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
  >
    <v-card>
      <v-card-title class="caption">
        <v-row>
          <v-col cols="12">
            <h2>Update Menu</h2>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="3">
              <label for="menuName"> {{typeUpdate === 'parent' ? 'Nama Menu' : 'Nama Sub Menu'}}</label>
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                id="menuName"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Nama Menu')]"
                label="Nama Menu"
                outlined
                v-model="form.menuName"
              ></v-text-field>
            </v-col>
            <v-col v-if="typeUpdate === 'parent'" cols="12">
              <v-row>
                <v-col cols="12" sm="3">
                  <label for="icon">Icon Menu</label>
                  <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-text-field
                    id="icon"
                    :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Icon Menu')]"
                    label="Icon Role"
                    outlined
                    v-model="form.icon"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="3">
              <label for="url">Path</label>
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                id="url"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Path')]"
                label="Path"
                disabled
                outlined
                v-model="form.url"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row
          justify="end"
          class="ma-0"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <v-btn
              color="red"
              class="white--text"
              small
              block
              @click="dialog = false"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-btn
              color="primary"
              class="white--text"
              small
              block
              :loading="isLoading"
              @click="submit"
            >
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    typeUpdate: {
      type: String,
      default: () => 'parent',
    },
  },
  data() {
    return {
      valid: false,
      dialog: false,
      isLoading: false,
      id: null,
      form: {
        menuName: '',
        icon: '',
        url: '',
        sort: 0,
        isActive: false,
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.id = null;
        this.form = {
          menuName: '',
          icon: '',
          url: '',
          sort: 0,
          isActive: false,
        };
      }
    },
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      try {
        this.isLoading = true;
        await this.$_services.menu.updateMenu({ form: this.form, menuId: this.id });
        this.$dialog.notify.success('Berhasil');
        this.$emit('changeItemOnItems', { id: this.id, menuName: this.form.menuName });
        this.dialog = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
