<template>
  <v-container fluid>
    <v-sheet rounded class="pa-4">
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <v-row>
            <v-col cols="12" sm="8" md="4">
              <FilterList
                :filter="filter"
                @refetch="refetch"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <TableList
            :filter="filter"
            :key="keyList"
            ref="tableList"
          />
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import TableList from './TableList.vue';
import FilterList from './FilterList.vue';

export default {
  name: 'page-role',
  components: {
    TableList,
    FilterList,
  },
  data() {
    return {
      keyList: 0,
      filter: {
        appType: this.$route.query.appType || 'web_management',
      },
    };
  },
  methods: {
    refetch() {
      this.keyList += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
  .container::v-deep
    .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:has(.bg-red) {
        background-color: rgba(255, 0, 0, 0.08) !important;
      }
</style>
